// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-form-js": () => import("./../../../src/pages/contact-form.js" /* webpackChunkName: "component---src-pages-contact-form-js" */),
  "component---src-pages-property-for-sale-index-js": () => import("./../../../src/pages/property/for-sale/index.js" /* webpackChunkName: "component---src-pages-property-for-sale-index-js" */),
  "component---src-pages-property-to-rent-index-js": () => import("./../../../src/pages/property/to-rent/index.js" /* webpackChunkName: "component---src-pages-property-to-rent-index-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-templates-areaguide-detail-template-js": () => import("./../../../src/templates/areaguide-detail-template.js" /* webpackChunkName: "component---src-templates-areaguide-detail-template-js" */),
  "component---src-templates-default-template-js": () => import("./../../../src/templates/default-template.js" /* webpackChunkName: "component---src-templates-default-template-js" */),
  "component---src-templates-news-detail-template-js": () => import("./../../../src/templates/news-detail-template.js" /* webpackChunkName: "component---src-templates-news-detail-template-js" */),
  "component---src-templates-property-details-js": () => import("./../../../src/templates/property-details.js" /* webpackChunkName: "component---src-templates-property-details-js" */),
  "component---src-templates-team-detail-template-js": () => import("./../../../src/templates/team-detail-template.js" /* webpackChunkName: "component---src-templates-team-detail-template-js" */)
}

