import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { client } from './client';
import { AuthStoreProvider } from '@starberry/myaccount-website-utils'
import {isIOS, isSafari} from 'react-device-detect'; 

const enableMyAccount = (process.env.GATSBY_MYACCOUNT || null) === '0' ? false : true

export const wrapRootElement = ({ element }) => (
  <AuthStoreProvider>
    <div className={`${enableMyAccount ? 'has-myacc' : 'no-myacc'} ${(isIOS || isSafari) ? "ios-device" : ""}`}>
      <ApolloProvider client={client}>{element}</ApolloProvider>
    </div>
  </AuthStoreProvider>
);
